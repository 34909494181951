<template>
  <master-layout bgColor="white">

    <h1>Regisztráció</h1>
    <ion-card>
      <ion-card-content>

        <ion-item>
          <ion-label position="stacked">Vezetéknév</ion-label>
          <ion-input type="text" v-model="userInfo.LastName"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">Keresztnév</ion-label>
          <ion-input type="text" v-model="userInfo.FirstName"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">E-mail</ion-label>
          <ion-input type="email" v-model="userInfo.email"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">Jelszó</ion-label>
          <ion-input type="password" v-model="userInfo.password"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">Jelszó megerősítése</ion-label>
          <ion-input type="password" v-model="userInfo.passwordConfirmation"></ion-input>
        </ion-item>

        <ion-item lines="none">
          <ion-checkbox
            slot="start"
            v-model="checkedAgreement"
            >
          </ion-checkbox>
          <p class="smallText pMargins">
            Elolvastam és elfogadom az <a href="https://www.orszagosbortura.hu/obt-adatvedelmi-tajekoztato-altalanos.pdf" target="_blank">Adatvédelmi tájékoztatóban</a> foglaltakat.
          </p>
        </ion-item>

        <ion-item lines="none">
          <ion-checkbox
            slot="start"
            v-model="checked18"
            >
          </ion-checkbox>
          <p class="smallText">
            Igazolom, hogy elmúltam 18 éves.
          </p>
        </ion-item>

        <div class="error-message ion-padding" data-testid="message-area">
          {{ errorMessage }}
        </div>
      </ion-card-content>
    </ion-card>
    <ion-button expand="full" shape="round" @click="createAccount">Regisztráció</ion-button>
    <ion-button expand="block" fill="transparent" color="medium" @click="$router.go(-1)">Mégsem</ion-button>
    <div class="bottomSpacer"></div>

  </master-layout>
</template>

<script>
import {
  IonCard, IonCardContent, IonItem, IonLabel, IonInput, IonCheckbox, IonButton, loadingController
} from '@ionic/vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    IonCard, IonCardContent, IonItem, IonLabel, IonInput, IonCheckbox, IonButton
  },
  data() {
    return {
      userInfo: {
        username: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        FirstName: '',
        LastName: ''
      },
      checkedAgreement: false,
      checked18: false,
      errorMessage: '',
      letLogout: null
    }
  },
  computed: {
    ...mapGetters('auth', {
      loginStatus: 'getLoginStatus'
    }),
    ...mapGetters('wineries', {
      wineryInMemory: 'getWineryInMemory'
    })
  },
  watch: {
    'userInfo.FirstName': function(newValue) {
      this.userInfo.FirstName = this.capitalizeFirstLetter(newValue)
    },
    'userInfo.LastName': function(newValue) {
      this.userInfo.LastName = this.capitalizeFirstLetter(newValue)
    }
  },
  methods: {
    ...mapActions('auth', {
      registerUser: 'registerUser',
      logoutUser: 'logoutUser'
    }),

    async createAccount() {
      if (this.userInfo.email && this.userInfo.password && this.userInfo.passwordConfirmation && this.userInfo.FirstName && this.userInfo.LastName) {
        if(!this.checkedAgreement){
          this.errorMessage = 'Kérjük, olvasd és fogadd el az Adatvédelmi tájékoztatóban foglaltakat.'
        } else if (!this.checked18){
          this.errorMessage = 'Kérjük, erősítsd meg, hogy elmúltál 18 éves.'
        } else if (this.userInfo.password != this.userInfo.passwordConfirmation){
          this.errorMessage = 'A két jelszónak egyezni kell.'
        } else {
          this.presentLoading()
          const userinfoUpdated = {
            username: this.userInfo.email,
            email: this.userInfo.email,
            password: this.userInfo.password,
            FirstName: this.userInfo.FirstName,
            LastName: this.userInfo.LastName
          }
          await this.registerUser(userinfoUpdated)
          await this.loading.dismiss()
          if(this.loginStatus === 'success'){
            // logout user for email validation
            this.logout()
          }else{
            if (this.loginStatus == 'Please provide valid email address.') {
              this.errorMessage = 'Helytelen e-mail formátum.'
            } else if (this.loginStatus == 'Email is already taken.') {
              this.errorMessage = 'Ilyen e-mail címmel regisztrált már felhasználó. Kérjük, válassz másikat!'
            } else if (this.loginStatus == 'Email already taken') {
              this.errorMessage = 'Ilyen e-mail címmel regisztrált már felhasználó. Kérjük, válassz másikat!'
            } else {
              this.errorMessage = 'Helytelen adatok.'
            }
          }
        }
      } else {
        this.errorMessage = 'Kérjük, töltsd ki az összes mezőt!'
      }
    },

    async logout() {
      await this.logoutUser()
      this.$router.push('/profil/email-konfirmacio')
    },

    async presentLoading() {
      this.loading = await loadingController
        .create({
          spinner: 'lines'
        })
        await this.loading.present()
    },

    capitalizeFirstLetter: (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }
}
</script>

<style scoped>
ion-button{
  margin: 20px 40px;
}
.pMargins{
  margin: 10px 0;
}
a:hover{
  color: inherit;
  text-decoration: underline;
}
</style>
